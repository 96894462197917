/* Genel ayarlar için değişkenler */
:root {
  --background-color: white;
  --button-background-color: #ffffff;
  --button-text-color: rgb(0, 0, 0);
  --overlay-background-color: rgba(0, 0, 0, 0.2);
}

/* Buton sheet ayarları */
.button-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--background-color);
  display: flex;
  justify-content: space-evenly;
  padding: 13px 0;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  z-index: 50;
  border-radius: 10px 10px
}

.sheet-button {
  
  margin: 0 5px;
  padding: 15px;
  font-size: 14px;
  background-color: var(--button-background-color);
  border-radius: 5px;
  border: none;
  color: var(--button-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
}

/* Modal ayarları */
.bottom-sheet-gallery-modal {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 20px 20px 0 0;
  max-height: 500px;
  overflow-y: auto;
  animation: slide-up 0.3s ease-out forwards;
  flex-direction: column;
  align-items: center;
}

/* Carousel ayarları */
.carousel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Resim ayarları */
.gallery-image {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
}

/* Medya sorgusu: Küçük ekranlar için ayarlamalar */
@media (max-width: 600px) {
  .button-sheet {
    padding: 5px 2;
  }

  .sheet-button {
    font-size: 12px;
    padding: 10px;
  }
}

/* Overlay ayarları */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlay-background-color);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 100;
}

/* Alt sheet ayarları */
.bottom-sheet {
  background-color: var(--background-color);
  border-radius: 15px 15px 0 0;
  width: 100%;
  animation: slide-up 0.3s ease-out forwards;
}

/* Kaydırma animasyonu */
@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

/* Menü başlık ayarları */
.menu-header {
  display: contents;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
}

/* Çentik ayarları */
.bottom-sheet::before {
  content: '';
  display: block;
  width: 40px;
  height: 5px;
  background-color: lightgray;
  border-radius: 2.5px;
  margin: 10px auto; /* Ortalar */
}

/* Kaydırma animasyonu */
@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

/* Alt menü başlangıç ve animasyon sınıfları */
.bottom-sheet {
  animation: slide-up 0.3s ease-out forwards;
}

.bottom-sheet.closing {
  animation: slide-down 0.3s ease-out forwards;
}

.no-scroll {
  overflow: hidden;
  height: 100%;
}

.slick-dots {
  position: absolute; /* Mutlak pozisyon */
  bottom: 10px; /* Açılan menünün altına yerleştirin */
  width: 100%; /* Tam genişlik */
  text-align: center; /* Ortalayın */
}

.slick-dots li button:before {
  font-size: 12px; /* Noktaların boyutu */
  color: #ccc; /* Varsayılan nokta rengi */
}

.slick-dots li.slick-active button:before {
  color: #007bff; /* Aktif noktanın rengi */
}
